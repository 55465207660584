var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    _vm.boxStyle == 'two'
      ? 'product-box-two'
      : _vm.boxStyle == 'three'
      ? 'product-box-three'
      : _vm.boxStyle == 'four'
      ? 'product-box-four'
      : 'product-box-one'
  ]},[(_vm.isLoading)?_c('div',[_c('v-skeleton-loader',{attrs:{"type":"image","height":_vm.boxStyle == 'two'
          ? '70'
          : _vm.boxStyle == 'three'
          ? '150'
          : _vm.boxStyle == 'four'
          ? '130'
          : '310'}})],1):_c('div',{class:['overflow-hidden', { 'rounded border': !_vm.noBorder }]},[(_vm.boxStyle == 'three')?_c('div',{staticClass:"position-relative bg-white",staticStyle:{"height":"147px","border-radius":"5px","overflow":"hidden"}},[_c('img',{staticStyle:{"width":"387px","height":"147px"},attrs:{"src":require("@/assets/img/product-three-bg.png"),"alt":""}}),_c('v-row',{staticClass:"lex-nowrap absolute-full",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"minw-0 flex-shrink-0",attrs:{"cols":null}},[_c('div',{class:['px-3 d-flex flex-column']},[_c('div',{staticClass:"fs-22 fc-vi mb-2"},[(
                  _vm.productDetails.base_price >
                  _vm.productDetails.base_discounted_price
                )?[_c('del',{staticClass:"opacity-40"},[_vm._v(" "+_vm._s(_vm.format_price(_vm.productDetails.base_price))+" ")]),_c('span',{staticClass:"fw-700"},[_vm._v(" "+_vm._s(_vm.format_price(_vm.productDetails.base_discounted_price))+" ")])]:[_c('span',{staticClass:"fw-700"},[_vm._v(" "+_vm._s(_vm.format_price(_vm.productDetails.base_discounted_price))+" ")])]],2),_c('h5',{class:[
                'opacity-60 fw-400 mb-4 lh-1-6 fs-13 text-truncate-2 h-40px'
              ]},[_c('router-link',{staticClass:"text-reset",attrs:{"to":{
                  name: 'ProductDetails',
                  params: { slug: _vm.productDetails.slug }
                }}},[_vm._v(" "+_vm._s(_vm.productDetails.name)+" ")])],1),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"me-3"},[(_vm.productDetails.stock)?[_c('button',{staticClass:"text-reset py-1 lh-1 align-center d-flex",on:{"click":function($event){return _vm.showAddToCartDialog({
                        status: true,
                        slug: _vm.productDetails.slug
                      })}}},[_c('i',{staticClass:"la la-shopping-cart fs-24 ts-05 me-1 fc-vi-red"}),_c('span',{staticClass:"fs-17 fc-vi-red"},[_vm._v(_vm._s(_vm.$t('buy_now')))])])]:[_c('span',{staticClass:"fw-700 fs-13 opacity-60"},[_vm._v(" "+_vm._s(_vm.$t('out_of_stock'))+" ")])]],2),_c('div',{staticClass:"mt-2 w-30px h-30px flex-center"},[(_vm.isThisWishlisted(_vm.productDetails.id))?[_c('button',{staticClass:"primary--text",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeFromWishlist(_vm.productDetails.id)}}},[_c('i',{staticClass:"la la-heart ts-02 fs-24"})])]:[_c('button',{staticClass:"primary--text btn-heart",attrs:{"type":"button"},on:{"click":function($event){return _vm.addNewWishlist(_vm.productDetails.id)}}},[_c('i',{staticClass:"la la-heart heart-bg-white ts-02 fs-16"})])]],2)])])]),_c('v-col',{staticClass:"flex-shrink-0",attrs:{"cols":"auto"}},[_c('div',{staticClass:"position-relative"},[(_vm.discount > 0)?_c('div',{staticClass:"discount-badge"},[_vm._v(" "+_vm._s(_vm.$t('off'))+" "+_vm._s(_vm.discount)+"% ")]):_vm._e(),_c('router-link',{staticClass:"text-reset d-block lh-0 text-center",attrs:{"to":{
                name: 'ProductDetails',
                params: { slug: _vm.productDetails.slug }
              }}},[_c('img',{staticClass:"img-fit size-150px",attrs:{"src":_vm.addResourceImage(_vm.productDetails.thumbnail_image),"alt":_vm.productDetails.name},on:{"error":function($event){return _vm.imageFallback($event)}}})])],1)])],1)],1):(_vm.boxStyle == 'vi-one')?_c('v-row',{staticClass:"bg-white",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"flex-shrink-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"position-relative"},[(_vm.discount > 0)?_c('div',{staticClass:"discount-badge"},[_vm._v(" "+_vm._s(_vm.$t('off'))+" "+_vm._s(_vm.discount)+"% ")]):_vm._e(),_c('router-link',{staticClass:"text-reset d-block lh-0 text-center",attrs:{"to":{
              name: 'ProductDetails',
              params: { slug: _vm.productDetails.slug }
            }}},[_c('img',{staticClass:"img-fit h-180px",attrs:{"src":_vm.addResourceImage(_vm.productDetails.thumbnail_image),"alt":_vm.productDetails.name},on:{"error":function($event){return _vm.imageFallback($event)}}})]),_c('div',{staticClass:"heart-top"},[(_vm.isThisWishlisted(_vm.productDetails.id))?[_c('button',{staticClass:"primary--text pt-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeFromWishlist(_vm.productDetails.id)}}},[_c('i',{staticClass:"la la-heart ts-02 fs-24"})])]:[_c('button',{staticClass:"primary--text btn-heart mt-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.addNewWishlist(_vm.productDetails.id)}}},[_c('i',{staticClass:"la la-heart text-white ts-02 fs-16"})])]],2)],1)]),_c('v-col',{staticClass:"minw-0 flex-shrink-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"px-3 d-flex flex-column py-2"},[_c('div',{staticClass:"fs-22 text-center fc-vi-red"},[(
                _vm.productDetails.base_price >
                _vm.productDetails.base_discounted_price
              )?[_c('del',{staticClass:"opacity-40"},[_vm._v(" "+_vm._s(_vm.format_price(_vm.productDetails.base_price))+" ")]),_c('span',{staticClass:"fw-700"},[_vm._v(" "+_vm._s(_vm.format_price(_vm.productDetails.base_discounted_price))+" ")])]:[_c('span',{staticClass:"fw-700"},[_vm._v(" "+_vm._s(_vm.format_price(_vm.productDetails.base_discounted_price))+" ")])]],2),_c('h5',{staticClass:"fw-400 mb-2 lh-1-6 fs-13 text-truncate-2 h-40px"},[_c('router-link',{staticClass:"text-reset",attrs:{"to":{
                name: 'ProductDetails',
                params: { slug: _vm.productDetails.slug }
              }}},[_vm._v(" "+_vm._s(_vm.productDetails.name)+" ")])],1),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"flex-grow-1 me-1"},[(_vm.productDetails.stock)?[_c('button',{staticClass:"text-reset py-1 lh-1 align-center d-flex vi-btn-box",style:(`${_vm.isBtnBg ? 'background-color: #AF74FF' : '' }`),on:{"click":function($event){return _vm.showAddToCartDialog({
                      status: true,
                      slug: _vm.productDetails.slug
                    })}}},[_c('span',{staticClass:"fw-700 fs-13",class:_vm.isBtnBg ? 'text-white' : 'fc-vi'},[_vm._v(_vm._s(_vm.$t('buy_now')))])])]:[_c('span',{staticClass:"fw-700 fs-13 opacity-60"},[_vm._v(" "+_vm._s(_vm.$t('out_of_stock'))+" ")])]],2)])])])],1):(_vm.boxStyle == 'four')?_c('v-row',{staticClass:"flex-nowrap bg-white",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"flex-shrink-0",attrs:{"cols":"auto"}},[_c('div',{staticClass:"position-relative"},[(_vm.discount > 0)?_c('div',{staticClass:"discount-badge"},[_vm._v(" "+_vm._s(_vm.$t('off'))+" "+_vm._s(_vm.discount)+"% ")]):_vm._e(),_c('router-link',{staticClass:"text-reset d-block lh-0 text-center",attrs:{"to":{
              name: 'ProductDetails',
              params: { slug: _vm.productDetails.slug }
            }}},[_c('img',{staticClass:"img-fit size-130px",attrs:{"src":_vm.addResourceImage(_vm.productDetails.thumbnail_image),"alt":_vm.productDetails.name},on:{"error":function($event){return _vm.imageFallback($event)}}})])],1)]),_c('v-col',{staticClass:"minw-0 flex-shrink-0",attrs:{"cols":null}},[_c('div',{staticClass:"px-3 d-flex flex-column py-2"},[_c('div',{staticClass:"fs-22 mb-2 fc-vi-red"},[(
                _vm.productDetails.base_price >
                _vm.productDetails.base_discounted_price
              )?[_c('del',{staticClass:"opacity-40"},[_vm._v(" "+_vm._s(_vm.format_price(_vm.productDetails.base_price))+" ")]),_c('span',{staticClass:"fw-700"},[_vm._v(" "+_vm._s(_vm.format_price(_vm.productDetails.base_discounted_price))+" ")])]:[_c('span',{staticClass:"fw-700"},[_vm._v(" "+_vm._s(_vm.format_price(_vm.productDetails.base_discounted_price))+" ")])]],2),_c('h5',{staticClass:"fw-400 mb-2 lh-1-6 fs-13 text-truncate-2 h-40px"},[_c('router-link',{staticClass:"text-reset",attrs:{"to":{
                name: 'ProductDetails',
                params: { slug: _vm.productDetails.slug }
              }}},[_vm._v(" "+_vm._s(_vm.productDetails.name)+" ")])],1),_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{staticClass:"me-3"},[(_vm.productDetails.stock)?[_c('button',{staticClass:"text-reset py-1 lh-1 align-center d-flex vi-btn-box",on:{"click":function($event){return _vm.showAddToCartDialog({
                      status: true,
                      slug: _vm.productDetails.slug
                    })}}},[_c('span',{staticClass:"fw-700 fs-13 fc-vi"},[_vm._v(_vm._s(_vm.$t('buy_now')))])])]:[_c('span',{staticClass:"fw-700 fs-13 opacity-60"},[_vm._v(" "+_vm._s(_vm.$t('out_of_stock'))+" ")])]],2),_c('div',[(_vm.isThisWishlisted(_vm.productDetails.id))?[_c('button',{staticClass:"primary--text pa-1 lh-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeFromWishlist(_vm.productDetails.id)}}},[_c('i',{staticClass:"la la-heart ts-02 fs-18"})])]:[_c('button',{staticClass:"primary--text pa-1 lh-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.addNewWishlist(_vm.productDetails.id)}}},[_c('i',{staticClass:"la la-heart-o ts-02 fs-18"})])]],2)])])])],1):_c('v-row',{class:[{ 'flex-nowrap': _vm.boxStyle != 'one' }],attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"flex-shrink-0",attrs:{"cols":_vm.boxStyle == 'one' ? '12' : 'auto'}},[_c('div',{staticClass:"position-relative"},[(_vm.discount > 0 && _vm.boxStyle != 'two')?_c('div',{staticClass:"discount-badge"},[_vm._v(" "+_vm._s(_vm.$t('off'))+" "+_vm._s(_vm.discount)+"% ")]):_vm._e(),_c('router-link',{staticClass:"text-reset d-block lh-0 text-center",attrs:{"to":{
              name: 'ProductDetails',
              params: { slug: _vm.productDetails.slug }
            }}},[_c('img',{class:[
                'img-fit',
                _vm.boxStyle == 'two'
                  ? 'size-70px'
                  : _vm.boxStyle == 'four'
                  ? 'size-130px'
                  : 'h-180px'
              ],attrs:{"src":_vm.addResourceImage(_vm.productDetails.thumbnail_image),"alt":_vm.productDetails.name},on:{"error":function($event){return _vm.imageFallback($event)}}})])],1)]),_c('v-col',{staticClass:"minw-0 flex-shrink-0",attrs:{"cols":_vm.boxStyle == 'one' ? '12' : null}},[_c('div',{class:[
            'px-3 d-flex flex-column',
            _vm.boxStyle == 'two' ? 'py-1' : 'py-2'
          ]},[_c('div',{class:[_vm.boxStyle == 'two' ? 'order-2 fs-14 lh-1 fc-zi' : 'fs-16 mb-2']},[(
                _vm.productDetails.base_price >
                _vm.productDetails.base_discounted_price
              )?[_c('del',{staticClass:"opacity-40"},[_vm._v(" "+_vm._s(_vm.format_price(_vm.productDetails.base_price))+" ")]),_c('span',{staticClass:"fw-700"},[_vm._v(" "+_vm._s(_vm.format_price(_vm.productDetails.base_discounted_price))+" ")])]:[_c('span',{staticClass:"fw-700"},[_vm._v(" "+_vm._s(_vm.format_price(_vm.productDetails.base_discounted_price))+" ")])]],2),_c('h5',{class:[
              'opacity-60 fw-400 mb-2 lh-1-6',
              _vm.boxStyle == 'two'
                ? 'text-truncate fs-12'
                : 'fs-13 text-truncate-2 h-40px'
            ]},[_c('router-link',{staticClass:"text-reset",attrs:{"to":{
                name: 'ProductDetails',
                params: { slug: _vm.productDetails.slug }
              }}},[_vm._v(" "+_vm._s(_vm.productDetails.name)+" ")])],1),(_vm.boxStyle != 'two')?_c('div',{staticClass:"d-flex align-center"},[_c('div',{class:[_vm.boxStyle == 'four' ? 'me-3' : 'flex-grow-1 me-1']},[(_vm.productDetails.stock)?[_c('button',{staticClass:"text-reset py-1 lh-1 align-center d-flex",on:{"click":function($event){return _vm.showAddToCartDialog({
                      status: true,
                      slug: _vm.productDetails.slug
                    })}}},[_c('i',{staticClass:"la la-shopping-cart fs-20 ts-05 me-1"}),_c('span',{staticClass:"fw-700 fs-13"},[_vm._v(_vm._s(_vm.$t('buy_now')))])])]:[_c('span',{staticClass:"fw-700 fs-13 opacity-60"},[_vm._v(" "+_vm._s(_vm.$t('out_of_stock'))+" ")])]],2),_c('div',[(_vm.isThisWishlisted(_vm.productDetails.id))?[_c('button',{staticClass:"primary--text pa-1 lh-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeFromWishlist(_vm.productDetails.id)}}},[_c('i',{staticClass:"la la-heart ts-02 fs-18"})])]:[_c('button',{staticClass:"primary--text pa-1 lh-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.addNewWishlist(_vm.productDetails.id)}}},[_c('i',{staticClass:"la la-heart-o ts-02 fs-18"})])]],2)]):_vm._e()])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }